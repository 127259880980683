/* src/components/Admin/CallDetails.css */

.whole-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.whole-container h2 {
    color: rgb(107, 114, 128);
}

.small-tiles {
    display: flex;
    flex-direction: column;
    /* Change to column layout */
    gap: 20px;
    justify-content: space-between;
    flex: 1;
}

.ScoreEdit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.details-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-evenly;
}

/* Styling for each <p> element */
.details-box {
    background-color: #fff;
    padding: 15px;
    border-radius: 20px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
    width: fit-content;
    height: max-content;
}

.dark .details-box {
    background-color: #212121;
}

.details-box p {
    margin: 0;
}

.popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    min-width: 50%;
    max-width: 90%;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
}

.dark .popup-content {
    background-color: #212121;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 20px;
}

.popup-button {
    text-decoration: none;
    background-color: inherit;
    font-size: large;
    padding: 6px;
    height: fit-content;
    border-width: 0;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.dark .popup-button {
    background-color: #141414;
    color: #fff;
}

.popup-button:hover {
    background-color: #212121;
    color: #fff;
    border-width: 0;
}