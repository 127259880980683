.admin-login-container {
    width: 50%;
    height: auto;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.admin-login-container h1 {
    text-align: center;
}

.admin-login-container div {
    margin-bottom: 10px;
}

.admin-login-container label {
    display: block;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 700;
}

.admin-login-container input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
    font-size: 20px;
}

.admin-login-container button {
    width: 100%;
    font-size: 20px;
    padding: 10px;
    border: none;
    border-radius: 3px;
    background-color: #007bff;
    color: #ffffff;
    cursor: pointer;
}

.admin-login-container button:hover {
    background-color: #0056b3;
}

.admin-login-container p {
    color: #ff0000;
    margin-top: 10px;
}

.login-page {
    margin: 200px auto;
}
