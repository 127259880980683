@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html .dark {
background-color: #141414;
}

::-webkit-scrollbar {
width: 5px;
}

::-webkit-scrollbar-thumb {
background: #888;
}

::-webkit-scrollbar-thumb:hover {
background: #f1f1f1;
}

::-webkit-scrollbar-track {
background: #555;
}

.toggle-dark-mode {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: transparent;
  color: inherit;
  border: none;
  cursor: pointer;
}

.cshadow {
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.grid-tile {
  margin: 10px;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.dark .grid-tile {
  background-color: #212121;
}

.grid-tile-2 {
  margin: 10px;
  padding: 20px;
  border-radius: 20px;
  width: auto;
  height: fit-content;
  align-self: center;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.dark .grid-tile-2 {
  background-color: #212121;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  max-width: 1550px;
  min-width: 425px;
  margin: 0 auto;
}

:root {
  --foreground: #fff;
  --background: #FAF0E5;
}

@font-face {
  font-family: "boldFont";
  src: url("/fonts/Futura Bold font.ttf") format("truetype"),
    url("/fonts/Futura Bold font.ttf") format("truetype");
}

@font-face {
  font-family: "normalFont";
  src: url("/fonts/Futura Book font.ttf") format("truetype"),
    url("/fonts/Futura Book font.ttf") format("truetype");
}

@font-face {
  font-family: "heavyFont";
  src: url("/fonts/Futura Heavy font.ttf") format("truetype"),
    url("/fonts/Futura Heavy font.ttf") format("truetype");
}

@font-face {
  font-family: "lightFont";
  src: url("/fonts/Futura Light bt.ttf") format("truetype"),
    url("/fonts/Futura Light bt.ttf") format("truetype");
}

@font-face {
  font-family: "mediumFont";
  src: url("/fonts/Futura Medium.ttf") format("truetype"),
    url("/fonts/Futura Medium.ttf") format("truetype");
}

* {
  font-family: Futura, Roboto, sans-serif !important;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "heavyFont";
}

h1 {
  font-size: 2rem;
}


span.MuiChip-label.MuiChip-labelSmall.css-wjsjww-MuiChip-label {
  font-family: 'lightFont' !important;
}

span.MuiChip-label.MuiChip-labelSmall {
  font-family: 'lightFont' !important;
}

.custom-width {
  width: 96.3%;
}

.text-2xl {
  font-size: 22px;
  line-height: 32px;
}

body {
  color: rgb(var(--foreground-rgb));
  background: rgb(var(--background-rgb));
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@keyframes animated-pop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.animated-pop {
  animation: animated-pop 0.5s ease-in-out;
}

.mx-22 {
  margin-left: 10px;
  margin-right: 10px;
}

@keyframes drop-bounce {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  30% {
    transform: translateY(0);
    opacity: 1;
  }

  50% {
    transform: translateY(-50%);
  }

  70% {
    transform: translateY(0);
  }

  90% {
    transform: translateY(-20%);
  }

  100% {
    transform: translateY(0);
  }
}

.animate-drop-bounce {
  animation: drop-bounce 1s ease-in;
}

@keyframes right-roll-in {
  0% {
    transform: translateX(-100vw) rotate(-720deg);
  }

  100% {
    transform: translateX(0) rotate(0);
  }
}

.animate-right-roll-in {
  animation: right-roll-in 2s ease-out;
}

@keyframes left-roll-in {
  0% {
    transform: translateX(100vw) rotate(720deg);
  }

  100% {
    transform: translateX(0) rotate(0);
  }
}

.animate-left-roll-in {
  animation: left-roll-in 2s ease-out;
}

@keyframes pop-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.animate-pop-in {
  animation: pop-in 1s ease-out;
}

.bg-gradient {
  background: linear-gradient(160deg, #FFC629, #B5DDEA, #B4B1E5);
  background-blend-mode: overlay;
}

.text-stroke {
  -webkit-text-stroke: 1px black;
}

.shadow-fxl {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}


.container {
  max-width: 1550px;
  margin: 0 auto;
  min-width: 425px;
}

.w-max-1550 {
  max-width: 1550px;
}

.min-w-425 {
  min-width: 425px;
}

.drop-down select {
  border: none;
  outline: none;
  background-color: inherit;
}

.drop-down select option {
  background-color: #f5f5f5;
  color: black;
}

.min-w-screen {
  min-width: 100vh;
}

.back-icon {
  color: black;
  font-size: 30px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 25px;
  padding: 5px;
  cursor: pointer;
}

.back-icon:hover {
  scale: 1.2;
}

.dark .back-icon {
  color: #fff;
  background-color: #212121;
}

.pback-button {
  background-color: white;
  color: black;
  border-radius: 25px;
  padding: 0;
  border-width: 0;
  height: 30px;
  width: 30px;
  align-self: center;
  cursor: pointer;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.dark .pback-button {
  background-color: #212121;
  color: white;
}

.dark .pback-button:hover {
  background-color: #141414;
  color: white;
}

.back-button {
  background-color: white;
  color: white;
  border-radius: 25px;
  padding: 0;
  border-width: 0;
  height: 30px;
  width: 30px;
  align-self: center;
  cursor: pointer;
}

.error-message {
  position: relative;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 10px;
  font-size: 18px;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  color: #888;
  font-size: 16px;
}

.error-messages-container {
  max-width: 50%;
  position: fixed;
  right: 50px;
  bottom: 50px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
}

.h3-darkgrey h3 {
  color: darkgrey;
}

@keyframes slide-in {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}

.slide-in {
  animation: slide-in 0.5s ease-out forwards;
}

.slide-out {
  animation: slide-out 0.5s ease-in forwards;
}