.details-container input {
    font-size: 20px;
    margin-top: 2px;
    width: 100%;
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.container input {
    font-size: 20px;
    margin-top: 2px;
    width: 100%;
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.details-container textarea {
    font-size: 20px;
    margin-top: 2px;
    width: 100%;
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.dark .details-container input {
    background-color: #212121;
    color: white;
}

.dark .container input {
    background-color: #212121;
    color: white;
}

.dark .details-container textarea {
    background-color: #212121;
    color: white;
}
.details-container p {
    margin: 0;
}

.details-container h1 {
    margin-top: 0;
}

.details-container h3 {
    margin-bottom: 10px;
    font-size: 20px;
    color:darkgrey;
}

.details-container h2 {
    margin-bottom: 0;
}

.update-button {
    border: 0;
    text-align: center;
    width: 100%;
    font-size: 21px;
    margin-top: 20px;
    margin-left: 0;
    padding: 10px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
}

.dark .update-button {
    background-color: #212121;
    color: white;
}

.update-button:hover {
    background-color: black;
    color: #fff;
    cursor: pointer;
}